import React from 'react'
import 'twin.macro'
import { useLocation, useNavigate } from "@reach/router"
import { useEffect, useState } from "react"
import { Spinner } from '../../components/elements/Loading'
import { ROUTES } from '../../components/layout/Navigation'
import { ErrorMessage } from '../../components/elements'
import { OnboardingStep, useMatchOnboardingState } from '../../content/onboarding/OnboardingStep'
import { useEmitCompletedPurchase } from '../../components/analytics/Events'


const OnboardingStepClaim = () => (
  <OnboardingStep
    step={4}
    desktopTitle={"Processing your details"}
    imageUrl='https://images.unsplash.com/photo-1461897104016-0b3b00cc81ee?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80'
  >
    <ClaimSpinner />
  </OnboardingStep>
)

const ClaimSpinner = () => {
  const { actions, state } = useMatchOnboardingState()
  const emitPurchase = useEmitCompletedPurchase()

  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(true)

  const navigate = useNavigate()
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const sessionId = params.get('sessionId')

  const registerSuccess = async () => {
    if(!sessionId) {
      setError(true)
      return
    }

    setError(false)
    setLoading(true)

    const payload = {
      stripeSessionId: sessionId,
      submissionId: state.submissionId,
      secret: state.secret
    }

    const result = await fetch('/.netlify/functions/match-onboarding-claim-checkout-session', {
      method: 'POST',
      body: JSON.stringify(payload)
    })

    if(!result.ok) {
      setError(true)
    } else {
      const { total, caseType } = await result.json()

      emitPurchase(total, caseType)

      actions.update({ completed: true })
      setLoading(false)
      navigate(ROUTES.onboarding.complete)
    }
  }

  useEffect(() => {
    registerSuccess()
  }, [])

  return (
    <div tw="p-8 w-full h-full flex flex-col items-center justify-center" style={{minHeight: '500px'}}>
      {error ? (
        <ErrorMessage title='We ran into a problem'>
          <p tw="mt-3 font-medium text-red-500">
            Don't worry, you haven't been charged.
          </p>
          <p tw="mt-3 font-medium text-red-500">
            Please try refreshing, and if this persists please email <a tw="underline" href="mailto:support@lawletter.co">support@lawletter.co</a> quoting your checkout ID:
          </p>
          <p tw="mt-3 font-medium text-red-500">
            <strong>{sessionId}</strong>
          </p>
        </ErrorMessage>
      )
      : <Spinner />
      }
    </div>
  )
}

export default OnboardingStepClaim
